import React from 'react'
import { ParallaxProvider } from 'react-scroll-parallax'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { createGlobalStyle, ThemeProvider } from 'styled-components'

import 'bootstrap/dist/css/bootstrap.min.css'
import '../fonts/fonts.css'

const theme = {
  textColor: '#988980',
  colorWhite: '#fff',
  colorBlack: '#000',
  colorRed: '#ab262d',
  colorGreen: '#4a7729',
  colorGreenLight: '#adcab8',
  fontMontserrat: `'Montserrat', sans-serif`,
  fontPlayfairDisplay: `'Playfair Display', serif`,
  fontSaaSeriesCD: `'Saa Series CD', sans-serif`,
  fontHelveticaNeueLTPro: `'Helvetica NeueLTPro', sans-serif`,
}

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #f9f8f6;
    color: ${(props) => props.theme.textColor};
    font-family: ${(props) => props.theme.fontPlayfairDisplay};
    font-size: 22px;
    font-weight: 400;
    line-height: 1.2;
    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 18px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 18px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 16px;
    }
    @media (max-width: 575px) {
      font-size: 16px;
    }
    &.drawer {
      overflow: hidden;
    }
  }

  .container {
    @media (min-width: 1200px) {
      max-width: 1110px;
    }
  }

  .rough-annotation {
    z-index: -1!important;
  }
`

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;700&family=Playfair+Display:ital,wght@0,400;0,700;1,400"
          rel="stylesheet"
        />
      </Helmet>

      <ParallaxProvider>{children}</ParallaxProvider>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
